import React from "react";
import _ from "lodash";
import { graphql } from "gatsby";
import tw, { styled } from "twin.macro";
import Layout from "../components/Layout";
import Header from "@shared/components/Header";
import { MAIN_COLOR, SITE } from "../config";
import Gallery from "@browniebroke/gatsby-image-gallery";

const Container = styled.div`
  ${tw`p-5`}
`;
const SectionTitle = styled.div`
  ${tw`mt-4 mb-2 text-xl text-gray-600`}
`;
const VideoListContainer = styled.div``;

const ImageListContainer = styled.div`
  ${tw`mx-[11px] my-2`}
`;

const MediaPage = ({ data, location }) => {
  const {
    seo,
    hero,
    tabs,
    title,
    videoList = [],
    imageList = [],
  } = data.markdownRemark.frontmatter;
  const galleryImgs = imageList.map((obj) => obj.image.childImageSharp);
  const imgCount = imageList.length;

  const rem3 = imgCount % 3;
  const rem4 = imgCount % 4;
  let colDivisor = 3;
  if (imgCount >= 4) {
    if (rem4 === 0) {
      colDivisor = 4;
    } else if (rem3 === 0) {
      colDivisor = 3;
    } else if (rem4 > rem3) {
      colDivisor = 4;
    } else {
      colDivisor = 3;
    }
  }
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {title && (
          <Header
            title={title}
            heroData={hero}
            tabsData={tabs}
            color={MAIN_COLOR}
            site={SITE}
          />
        )}
        <SectionTitle>Videos</SectionTitle>
        <VideoListContainer>
          {videoList.map((obj) => (
            <iframe
              width="100%"
              height="520"
              src={obj.url}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          ))}
        </VideoListContainer>
        <SectionTitle>Photos</SectionTitle>
        <ImageListContainer>
          <Gallery
            images={galleryImgs}
            colWidth={50}
            mdColWidth={100 / colDivisor}
          />
        </ImageListContainer>
      </Container>
    </Layout>
  );
};

export default MediaPage;

export const pageQuery = graphql`
  query MediaPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "media-page" } }) {
      html
      frontmatter {
        seo {
          title
          description
        }
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        title
        videoList {
          url
        }
        imageList {
          image {
            childImageSharp {
              thumb: gatsbyImageData(placeholder: BLURRED)
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
